<template>
  <v-container style="overflow: hidden">
    <v-layout align-center justify-center style="margin-top: 150px">
      <v-flex xs12 sm8 md4>
        <v-layout align-center justify-center>
          <img
            class="justify-center"
            src="@/assets/logo_blanco.svg"
            height="65px"
          />
        </v-layout>
      </v-flex>
    </v-layout>
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          class="loginEspacio"
          light
          max-width="100%"
          width="400"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h2>Acceso Panel Comerciales</h2>
            </div>
          </template>
          <v-card-text>
            <v-form v-on:submit.prevent id="check-login-form">
              <v-text-field
                color="primary"
                label="Email"
                prepend-icon="mdi-email"
                data-test="vfieldLogin"
                name="formlogin"
                type="text"
                v-model="formlogin"
              />

              <v-text-field
                color="primary"
                label="Contraseña"
                prepend-icon="mdi-lock-outline"
                data-test="vfieldPassword"
                name="formpassword"
                id="password"
                type="password"
                v-model="formpassword"
              />
            </v-form>
          </v-card-text>
          <v-alert
            :value="loginAlert"
            type="error"
            transition="scale-transition"
            >{{ this.textoError }}</v-alert
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="checkLogin"
              class="v-btn-login"
              @click="checkLogin"
              color="primary"
              type="submit"
              form="check-login-form"
              >Entrar</v-btn
            >
          </v-card-actions>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
export default {
    data () {
        return {
            textoError: '',
            token: '',
            url: process.env.VUE_APP_API_URL,
            formlogin: '',
            formpassword: '',
            loginAlert: false
        }
    },
    components: {
        'base-material-card': () => import('./base/MaterialCard.vue')
    },
    methods: {
        checkLogin () {
            const goLogin = async () => {
                const config = {
                    headers: {
                        // eslint-disable-next-line
            accept: "application/json",
                        'Content-Type': 'application/json'
                    }
                }
                const data = {
                    email: this.formlogin,
                    password: this.formpassword
                }
                try {
                    return await this.$axios.post(
                        this.url + '/distribuidores/users/login',
                        data,
                        config
                    )
                } catch (error) {}
            }

            const processLoginResult = async () => {
                const login_result = await goLogin()
                if (login_result) {
                    this.token = login_result.data.token
                    if (this.token !== undefined) {
                        this.$store.dispatch('setLoggedIn')
                        this.$store.dispatch('setToken', this.token)
                        this.$store.dispatch('setjsonPedido', '')
                        this.$store.dispatch('setdatosClienteValidados', '')
                        this.$store.dispatch(
                            'setComercial',
                            login_result.data.distribuidor
                        )
                        if (!login_result.data.distribuidor.padre) {
                            this.$router.push('/crearPedido').catch(() => true)
                        } else {
                            if (login_result.data.distribuidor.estadoActivo) {
                                this.$router.push('/crearPedido').catch(() => true)
                            } else {
                                this.textoError =
                  'Usuario no activo. Es necesario activarlo para poder iniciar sesión.'
                                this.$data.loginAlert = true
                            }
                        }
                    } else {
                        this.textoError = 'Error! Credenciales incorrectas.'
                        this.$data.loginAlert = true
                    }
                } else {
                    this.textoError = 'Error! Credenciales incorrectas.'
                    this.$data.loginAlert = true
                }
            }
            processLoginResult()
        }
    }
}
</script>
<style>
.loginEspacio {
  margin-top: 60px !important;
}
</style>
